/* eslint no-irregular-whitespace: 0 */
import React from 'react';

const Page5 = () => {
  return (
    <div className="page-novel">
      <h2 className="page-novel-hader">午後6時、渚にて</h2>
      <p className="page-novel-text">
        満たされない体を満たすように、欠けた記憶を埋めるように、拾ったものをトランクに詰めた。
        <br />
        そんなことをしても、この身は空っぽなのに。
        <br />
        <br />
        <br />
        <br />
        オーエンが目を開けるとそこは波打ち際だった。歩くと靴は砂に沈み込む。ほとんど空は暗く、海との境目だけが、薄赤く燃えている。あの星はまだ空にある。
        <br />
        カインはあたりにいない。これまではすぐ近くにいたのだが、今回は離れたところに落とされたらしい。あたりを見渡すと波打ち際を辿ったずっと先で手を振っている人影が見えた。カインが向こうから歩いてくる。他に人影はない。
        <br />
        日暮れの海辺だが、それほど寒くない。今度は南かとオーエンはあたりをつけた。南の国にはあまり来たことがない。人のいない海辺は特に。
        <br />
        カインは魔法舎に帰ると言った。けれど、オーエンは魔法舎を帰る場所だと思ったことはなかった。長い旅の途中で滞在した場所の一つに過ぎない。いつかは去る場所だ｡
        <br />
        帰ると言うのなら、もっと終わりのときに行き着く場所がいい。例えば、海のような。しかし、この海は終着点にしては温すぎる。
        <br />
        カインの姿がはっきりと見えるほど近づいていた。カインはまだ終わりのない世界を知らない。オーエンはそれが苛立たしいと同時にどうしようもなく愛しい。彼に絶望が訪れることを待ちながら、それでも矜持を持って歩き続ける姿を望む。矛盾した2つの感情は苦しく、それなのに何故か甘いと思うのだ。
        <br />
        <br />
        <br />
        <br />
        カインが気がつくと波打ち際にいた。あたりに人の姿はない。たとえあってもカインには見えない。誰もいない世界は、まるで死んでしまった世界のようで少し恐ろしい。
        <br />
        けれども、見渡せば遠くにオーエンの姿が見えた。その姿はほんの少し星に似ている。この世界の終わりの日にも空の上で輝く真っ白な星。
        <br />
        カインはオーエンの方に歩く。彼はカインに気づいているようだったが、その場で海の方を眺めている。声を張り上げずとも届く距離まで近づいた時、オーエンは唐突にしゃがみ込んで海水に指を伸ばした。真っ白な指先が波に飲まれる。瞳は真剣に指先を見つめていた。
        <br />
        カインがいることに気がつくと、彼は花が咲くように笑った。
        <br />
        「騎士様！」
        <br />
        その姿にカインは自分が知るオーエン不在を感じる。混じり気のない素直な表情に何故か胸がざわつく。
        <br />
        「ほら、これ綺麗でしょ。騎士様にもあげる」
        <br />
        オーエンの掌の中にあったのは小さな白い貝殻だった。
        <br />
        「ああ……。ありがとう」
        <br />
        受け取るとオーエンは自慢げに胸を張った。
        <br />
        「もっと綺麗なの見つけるよ」
        <br />
        オーエンは貝殻を拾い集める。手近にあった魔道具のトランクを開けようとするのを、カインは慌てて止めた。このオーエンに魔法使いである自覚はない。「正しく」開けなければ何が出てくるかわからないトランクを開けさせるわけにはいかなかった。
        <br />
        「俺が持ってるから」
        <br />
        オーエンは素直にカインに貝殻を渡す。それを奪われたり、壊されたりすることを微塵も疑わない顔だ。
        <br />
        「たくさん集めて、鞄の中をいっぱいにするんだ」
        <br />
        オーエンは歌うように告げると砂浜を掘る。カインもオーエンの隣にしゃがみ込むと、手の中の貝殻を弄びながら、尋ねる。
        <br />
        「鞄の中をいっぱいにしてどうするんだ？」
        <br />
        <br />
        <br />
        <br />
        その問いにオーエンははっと顔をあげた。砂塗れの指。濡れた靴。目の前にあるカインの顔。その全てを認識して、オーエンは自らの意識が眠っていたことを知る。
        <br />
        「オーエン……？」
        <br />
        オーエンが強く息を吸い込むと喉の奥が鳴った。カインは砂だらけのオーエンの手を握った。ここに彼の体があると伝えるように。海水に浸した指先は冷たく、死人のように白かった。
        <br />
        厄災の傷はそれを受けたどの魔法使いにとっても大きな影響を与えた。カインにとっても触れるまで人間や魔法使いが見えないというのは視界を奪われたに等しく、こうして見知らぬ土地を訪れた時には恐ろしさも頼りなさも感じる。
        <br />
        けれど、オーエンの傷は不便であるとか、恐ろしいとか、そういうのを超えて彼を少しずつ殺すものようにカインには思えた。そこにいるオーエンという魔法使いの矜持を、存在を揺るがす。他者を認識できないこと以上に自己を認識できないというのはどれだけ頼りないだろうと思う。そして、こうやってカインが心配すること自体が、オーエンを傷つけるのだ。
        <br />
        オーエンは深く息をつくと、カインの手を振り払って歩いた。集めていた貝殻は踏みつけられて、砂に埋れていく。
        <br />
        「どれだけ集めても空っぽのままだ」
        <br />
        オーエンはトランクを掴む。そこにどれだけの物が詰まっていても魔道具であるトランクは重さを感じさせない。それは持ち主とよく似ている。
        <br />
        日は落ちて、夜がやってくる。星を捕まえることは叶わない。けれど、オーエンにはもうどうだってよかった。星を追いかける旅路は、彼が今までしてきたことと何も変わりはしないのだから。
        <br />
        いっそ──。
        <br />
        「このまま彷徨い続けようか」
        <br />
      </p>
    </div>
  );
};
export default Page5;
