import React, { FC } from 'react';
import Page from './Page';

type Props = {
  pageId: number;
};
const Novel: FC<Props> = props => {
  const { pageId } = props;

  return (
    <div className="popup-novel">
      <Page pageId={pageId} />
    </div>
  );
};
export default Novel;
