/* eslint no-irregular-whitespace: 0 */
import React from 'react';

const Page7 = () => {
  return (
    <div className="page-novel">
      <p className="page-novel-text">
        そこは何もない、闇の中だった。
        <br />
        <br />
        <br />
        <br />
        左右や上下の感覚すらあやふやになる空間にカインはいた。しばらくすれば目も闇に慣れるはずと思ったが、一向に視界が明るくならない。
        <br />
        「《グラディアス・プロセーラ》」
        <br />
        魔法で明かりを生み出すと、ようやく自分の手元は明るくなったが、周囲はどこまでも闇に包まれている。オーエンの姿もない。
        <br />
        闇の方へと足を進める。少し歩いても壁にぶつからないということはある程度広い空間らしい。けれど、空気の流れは感じられない。嫌な感じのする場所だ。
        <br />
        星を捕まるのではなく、壊した結果がこれだ。オーエンを問い詰めたい気持ちはあるが、本人の姿が見えないのだからしょうがない。
        <br />
        「出口……はあるんだろうか」
        <br />
        道に迷ったときは動き回らないほうがいいとわかっている。けれどこの暗闇の中でじっとしていることもできず、カインは足を進めた。
        <br />
        歩きながら上着のポケットの中を探る。貝殻とメッセージが走り書かれた紙片、そして鈴。
        <br />
        「そういえば──」
        <br />
        失せ物を見つける鈴はカインの言葉に答えるように鳴った。揺らさずとも掌の上からは音が聞こえる。
        <br />
        カインは鈴を揺らす。再びよく響く音がしたが、それは手元の鈴から聞こえるものではない。カインが鈴を鳴らす度、別の場所から音が聞こえる。合奏のように近くから、遠くから鈴の音が響く。
        <br />
        遠くの鈴の音が鳴る方向に向かってカインは進んだ。災厄の傷のせいで、前より音や人の気配に敏感になったおかげか、音のなる方を特定するのは容易い。近づくたびに音は大きくなる。
        <br />
        リン──と響く音は目の前にあった。さらに一歩近づけば、カインの魔法が照らす範囲に入る。オーエンは暗闇の中でもなお白く美しかった。
        <br />
        鈴はオーエンの指先にかけられた紐につながっていた。オーエンが鈴を揺らすとカインの手元で音がなった。
        <br />
        「失せ物を見つける鈴ね」
        <br />
        オーエンはカインを上から下まで眺めてからくすりと笑った。
        <br />
        「足のついたものにつけておくにはいいかも」
        <br />
        「手帳や靴下と同じ扱いは勘弁してくれ」
        <br />
        カインも思わずつられて笑った。
        <br />
        暗闇の世界で、たった二人。何もない世界だからこそわかる。トランクの中身が、彼の身の内が、どれだけ満たされなくとも、カインの目には鮮やかにオーエンの質量を感じる。他の人や魔法使いよりは軽く、けれど確かにそこにある。
        <br />
        「帰ろう」
        <br />
        カインとオーエンが巡った場所はどこも美しく、心地よい場所だった。けれど、そのどこにいても彼らは客人だった。もっとはっきり言ってしまえば異物だ。この暗闇もまた、彼らを歓迎はしない。
        <br />
        「どこへ？」
        <br />
        オーエンは問う。
        <br />
        「魔法舎へ」
        <br />
        「そこは、いつか失われる場所だ」
        <br />
        「いつか離れるとしても、今必要とされている場所だから」
        <br />
        誰に？
        <br />
        その答えは自然とカインの口をついた。
        <br />
        「賢者が待ってる」
        <br />
        オーエンは嘆息する。
        <br />
        「結局は賢者様か。まあいいや。これで呪いが解ける」
        <br />
        オーエンの言葉に呼応して空間が揺れた。
        <br />
        「なんだこれ」
        <br />
        「作りが露呈した呪いは脆い。巧妙に閉じ込めていたのに最後の最後で甘いんだよ。せっかく『賢者』を僕たちから奪ったのに」
        <br />
        　オーエンはトランクの留め具に手をかける。
        <br />
        「《クーレ・メミニ》　大した餌にはならないだろうけど」
        <br />
        　現れた獣は暗闇を喰む。空間が壊れてゆく。
        <br />
        カインはポケットの中にあった紙片を取り出す。その言葉が誰のものなのか今ならわかる。
        <br />
        「《グラディアス・プロセーラ！》」
        <br />
        紙片はカインの魔力を受けると光を生んだ。
        <br />
      </p>
    </div>
  );
};
export default Page7;
