/* eslint no-irregular-whitespace: 0 */
import React from 'react';

const Page4 = () => {
  return (
    <div className="page-novel">
      <h2 className="page-novel-hader">午後3時　帰り道</h2>
      <p className="page-novel-text">
        雨を避けようと思って星を追いかけた。それなのに、オーエンの右手が星に触れると、顔は冷たい雨で濡れた。
        <br />
        「雨……」
        <br />
        オーエンとカインは気がつくと雨の中に立っていた。土砂降りというほどではないが、間断なくしとしとと降り続いている。上を見れば頭上は曇り空で、それなのに星が1つはっきりと見えた。これでは星を捕まえようがない。
        <br />
        先ほどまでいた山ほど高くはないが峠道のようで、もうしばらくは登りが続く道の上にオーエンはいる。
        <br />
        「《クアーレ・モリト》」
        <br />
        呪文を唱え、自身の周りに薄く雨除けを張る。全く以って苛立たしい。
        <br />
        「まいったな」
        <br />
        数歩分下った場所に立っているカインは天を仰ぐ。濡れた前髪が額に張り付いている。
        <br />
        「そうしてると濡れ鼠になっちゃうよ」
        <br />
        「うるさいな……」
        <br />
        カインは深呼吸をひとつつくと、意を決したように呪文を唱える。
        <br />
        「《グラディアス・プロセーラ》」
        <br />
        カインの髪がふわりと揺れる。
        <br />
        「よくできました」
        <br />
        オーエンはつまらなさそうに告げる。とかく大雑把だったカインの魔法は、教師が良いのかここ最近随分とこなれてきた。
        <br />
        「で、どうするかだな」
        <br />
        追いかけるしかないのだが、これでは埒があかない。カインは仕舞い込んだ紙片を読み返す。
        <br />
        「捕まえる方法が書いてないんだよな……」
        <br />
        この分だと普通に捕まえるのでは駄目なのだろう。しかし、その先の答えに辿りつかない。
        <br />
        オーエンとカインはどちらともなく峠の道を下る。上るには奇妙な星に振り回された2人は疲れ過ぎていた。さりとて再び星を追いかけるのも同じことの繰り返しだろうと思うと、自然と足を動かす先は決まっていた。
        <br />
        元いた場所が見えないくらいまで下ると人の気配がした。峠の斜面は緩やかになりほぼ平らになったところに1台の馬車がある。
        <br />
        「何かあったのか？」
        <br />
        カインは目を細める。人の姿は見えないが人の気配がある。
        <br />
        「可哀想に。馬車の車輪が道の窪みに嵌ったみたい」
        <br />
        オーエンは愉快そうに笑った。馬車の荷台を押し上げようとする人の姿が見える。道の窪みには雨が溜まっている。それほど大きくはないがちょうど片側の車輪が嵌って荷台が大きく傾いていた。
        <br />
        「大変じゃないか」
        <br />
        水たまりを蹴って、カインは馬車の方に走りだす。オーエンはその後ろ姿を黙って見つめていた。馬車を押していたのは
        <br />
        男性が1人。カインは声をかけると並んで馬車の荷台を押した。馬の嘶きからは疲労が感じられる。
        <br />
        オーエンは荷台の横を抜けて馬に近づく。御者台で手綱を握っているのは12、3歳くらい少年だった。荷台を押す男の子供だろうか。不安げな顔をしながら荷台を押す男の合図で馬に荷台を引かせていた。彼はオーエンに気がつくと驚いた顔をする。オーエンはそれを無視して馬に近づいた。
        <br />
        2頭の馬はどちらもよく可愛がられているようで、疲れた様子であっても、めいいっぱい馬車を引いている。
        <br />
        「可哀想に」
        <br />
        人に飼い慣らされた獣は哀れなほどに従順だ。オーエンは馬たちを馬車から放ってやろうかと思っていたがやめた。たとえ轡を外しても、馬はここを離れないだろうと確信したからだ。
        <br />
        代わりにたてがみを撫でてやりながらオーエンは耳元で囁く。
        <br />
        「あと一息頑張ってご覧。手伝ってあげるから」
        <br />
        オーエンが馬の背中を叩くと馬は渾身の力で荷台を引く。後ろから「抜けた！」という声が上がり、馬車はようやく道の上を進む。
        <br />
        少年は窪みから十分離れたところで馬を止める。
        <br />
        「手伝ってくれたのか？」
        <br />
        カインはオーエンに近づくとそう尋ねた。
        <br />
        「さあ？　邪魔しようと思ったのかもよ」
        <br />
        荷台の中身はわからないが、馬が2頭とも逃げ出してしまったら、置いていくしかない。そうなれば彼らは途方に暮れる。そこに近づき、惑わせるのがいつものオーエンのやり口だった。不安に駆られた人間は容易くオーエンの掌の上で踊る。今回はそうならなかったというだけだ。
        <br />
        「本当にありがとうございました」
        <br />
        馬車を押していた男はカインとオーエンに深々と頭を下げた。
        <br />
        「いや、力になれたならよかった」
        <br />
        カインはなんでもないように笑う。
        <br />
        男は息子と共に馬車で峠を越えて町から町へと荷を運ぶ仕事をしているらしい。荷台を失えば、荷運びの労賃だけでなく信用も失うところだったと何度も頭を下げた。
        <br />
        「良ければ麓の町までご一緒しませんか。荷台の隙間は狭いですが、雨は避けられます。町に着いたらお礼もさせてください」
        <br />
        礼には及ばないと固辞しつつ、麓まで馬車に乗せてもらうことにした。幌の隙間から荷台に上がる。荷台にはみっちりと荷が積まれているが縁に腰掛ける隙間はあった。
        <br />
        「これをどうぞ」
        <br />
        少年が、クッションを出してくれる。こうやって人を運ぶこともあるのだろう。オーエンとカインは並んで座る。
        <br />
        興味津々といった目で少年はカインとオーエンを見比べる。カインは苦笑するとオーエンの方に寄って、少年に手を伸ばして声をかけた。
        <br />
        「このあたりの話でも聞かせてくれよ」
        <br />
        少年はカインの手をとると嬉しそうに荷台に上る。始めからそのつもりがあったのだろう。「父さん！　出しても大丈夫」と御者台にいる父親に声をかけた。
        <br />
        「お兄さんたちは旅の人？」
        <br />
        「そんなところ」
        <br />
        カインが答えると少年は「珍しい」と驚く。
        <br />
        「このあたりは何もないでしょう？」
        <br />
        「そうなのか？」
        <br />
        「この峠を降りた先に俺の町があって、そこからもう少し先にも村はあるけど……。でも特別何か珍しいものがあるわけじゃない普通の町だよ」
        <br />
        少年の話をまとめると、このあたりは少年たちの住む町に人が集まって生活しているらしい。大きな街道からは離れており、旅人が訪れることは少ないようだ。
        <br />
        「ねえ、このあたりに星が出てくる言い伝えはない？」
        <br />
        オーエンは少年に尋ねた。ずっと黙っていたオーエンが口を開いたので、少し驚きつつ答えた。
        <br />
        「星……。『一番星を見つめたらいけない』ってやつかな」
        <br />
        「なんだそれ？」
        <br />
        「一番星が見えたら帰り道を急げ、って意味だと思うよ。このあたりは日が暮れると一気に暗くなるから」
        <br />
        ほら、と少年は指を指す。いつの間にか雨は上がっていて幌の隙間から外を見やると遠くの空は茜色になっていた。馬車は峠を越えて帰り道を走る。
        <br />
        「お兄さんたちはどこから来たの？」
        <br />
        「中央の国の方から」
        <br />
        カインの答えに少年は目を丸くする。
        <br />
        「そんな遠いところから旅をしているんだね」
        <br />
        聞けばここは東の国だそうだ。町の名前を聞いてもカインもオーエンもぴんと来ない。
        <br />
        「それでどこへ行くの？」
        <br />
        少年に問われたが、この先どこへ行くのかカインもオーエンも答えは持っていない。全ては頭上に輝く星の導くままだ。
        <br />
        「俺たちも帰り道なんだよ」
        <br />
        カインはそう答えた。間違ってはいないだろう。
        <br />
        <br />
        <br />
        <br />
        馬車は日暮れ前に町に着く。宿らしい宿もないから泊まっていかないかと言われたが、丁寧に断って町の入り口で分かれた。心配そうな少年に「大丈夫だよ」とカインは笑いかける。
        <br />
        「帰り道を急いでいるからさ」
        <br />
        人間は夜に町を離れることはほとんどない。夜の山や森は危ないからだ。父親である男は何か察したらしく、「お気をつけて」と言い、水とパンを分けてくれた。
        <br />
        　赤い夕焼けが空を覆っていく。隣にいるオーエンは眩しげに夕焼けに浮かぶ星を見ていた。なんらかの魔法である星は、この夕焼け空にあってもはっきりと視える。
        <br />
        「帰らないとな」
        <br />
        それは自然とカインの口をついた。子供の頃のカインにとって夕焼けは家に帰る合図のようなものだった。
        <br />
        「帰る……？」
        <br />
        オーエンはその言葉の意味を確かめるように繰り返した。
        <br />
        「魔法舎にだよ」
        <br />
        その時カインは、オーエンにとって帰る場所は長い間なかったのだろうと思った。魔法使いオーエンはどこにでも現れる。けれど、そんな言い伝えの裏を返せば彼はどこにもいないということだ。
        <br />
        オーエンは過去の話をしたがらなかった。当然生まれ育った場所の話を聞いたこともない。そこが今もあるのかすら、あの北の国では怪しい。オーエンは時々星を見つめている。それは、彼の行き先は、帰る場所ではなくていつだって星の指す方角にあるからなのではないだろうか。
        <br />
        「それなら一番星が出る前に行かなくちゃ」
        <br />
        オーエンはふっと笑った。それを見て、カインは何かに突き動かされるようにオーエンの手をとった。はぐれてしまわないように。
        <br />
        こんなでたらめな状況でもどこかで楽しいという気持ちがあった。けれど、最後に帰らなくてはとカインは決意する。
        <br />
        「どうしたの？」
        <br />
        「ええと……」
        <br />
        手を繋いでいては空を飛べない。疾った心をごまかすために掴んだオーエンの手を頭上にあげる。
        <br />
        「絶対帰るぞー！　おー！」
        <br />
        「は？」
        <br />
        氷のようなオーエンの視線が向けられた。手を振り解かれると、彼は魔法で箒を取り出す。
        <br />
        「結局何の手掛かりもなかったけどね。何か手がかりがあって飛ばされてるのかと思ったけど、ヒントでもなんでもないじゃない。ただの気まぐれ」
        <br />
        「いや、まったくだな……」
        <br />
        「騎士様はなんかおかしいし」
        <br />
        「別におかしくないぞ」
        <br />
        「そう？」
        <br />
        2人並んで夕焼け空を飛ぶ。星はカインが手に取った。
        <br />
      </p>
    </div>
  );
};
export default Page4;
