/* eslint no-irregular-whitespace: 0 */
import React from 'react';
import './Page.css';

const Page1 = () => {
  return (
    <div className="page-novel">
      <h2 className="page-novel-hader">夜明け前、プロローグ</h2>
      <p className="page-novel-text-italic">
        　その星を追いかけて。
        <br />
        　どうか、夜になる前につかまえて。
        <br />
        　あなたたちならきっと、
      </p>
      <p className="page-novel-text">
        その紙片は気づいた時にはカインの手の中にあった。筆致に見覚えはない。走り書いたようにやや文字が跳ねている。「きっと、」のその先に何を言いたかったのだろう。送り主不明の手紙について考えを巡らせていると、苛立つような声が降ってきた。
        <br />
        「ぼうっとしていないで、そろそろ出発するよ」
        <br />
        目の前にいるオーエンは帽子をかぶり直し、トランクを手に取った。帽子の鍔の下──絹のような銀の髪の隙間から色違いの双眸がカインを見つめていた。
        <br />
        「悪い」
        <br />
        手紙をカインはポケットに仕舞った。代わりに箒を取り出す。
        <br />
        空はようやく明るみ始めている。山の稜線は白く、頭上を見上げれば薄い紫。いつもならカインもまだ眠っている時間だ。
        <br />
        星を追いかけるなんておかしなことを言うものだと思った。けれど、カインの目の前には確かにそれがある。
        <br />
        「やっぱり見えるな」
        <br />
        星だ。夜明けの空に珍しい星。それも、一際明るく輝いている。今まで見たどんな星よりも明るく大きい。
        <br />
        「星だなんて馬鹿馬鹿しい。ただの魔法だよ」
        <br />
        オーエンは星を睨みつけて言った。自然と空に輝く星でないことはカインにもわかる。あまりにも不自然だからだ。けれど、あれが魔法の産物だと言われるとよくわからない。魔力を感じるような気もするが、まるで水の中でものを見るような、ぼんやりとした感覚だ。
        <br />
        「とりあえず追いかけるしかないだろ」
        <br />
        カインの言葉にオーエンは頷かなかったが、箒を手に取ると空へと浮かび上がる。
        <br />
        <br />
        <br />
        <br />
        カインとオーエンは目覚めるとここにいた。手元にあったのは短い文が書かれた手紙だけ。送り主もわからないそれに従う義理はないはずだが、どうしてか無碍にしてはならないという確信があった。オーエンも同じようで、苛立たしげにしながらも、あの奇妙な星を追いかけることに反対はしない。
        <br />
        何かおかしなことが起こっている。けれど、何がおかしいのかが認識できない。手がかりは東の空に輝くあの星だけだ。
        <br />
        カインの前をオーエンは飛ぶ。白いジャケットが刻々と変化する空の中でも、旗のように目に留まる。空は紫から青へ。
        <br />
        旅をするには都合良く、今日は晴れそうだった。
        <br />
      </p>
    </div>
  );
};
export default Page1;
