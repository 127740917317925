import React, { FC, useState } from 'react';
import ReadFlag from './ReadFlag';
import Cover from './components/Cover';

const App: FC = () => {
  const [readFlag, setReadFlag] = useState(new ReadFlag(0, false));
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [clickPageId, setClickPageId] = useState(0);

  return (
    <div className="App">
      <Cover
        readFlag={readFlag}
        setReadFlag={setReadFlag}
        setClickPageId={setClickPageId}
        clickPageId={clickPageId}
        isModalOpen={modalIsOpen}
        setModalIsOpen={setModalIsOpen}
      />
    </div>
  );
};

export default App;
