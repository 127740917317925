/* eslint no-irregular-whitespace: 0 */
import React from 'react';

const Page8 = () => {
  return (
    <div className="page-novel">
      <h2 className="page-novel-hader">
        日付が変わるまであと十数分、エピローグ
      </h2>

      <p className="page-novel-text">
        「カイン、オーエン」
        <br />
        オーエンが目を開けると、目の前には安堵の二文字を浮かべた顔があった。弱々しい人間のくせに、一人前に心配なんてしてくる。
        <br />
        結局のところ、呪いを解く鍵はあの紙片だった。メッセージそのものではなく、その送り主こそが鍵。鍵がなくても無理矢理破る自信はあったが、その時はとりあえずこの場所が爆発したり、今目の前にいる人間が吹き飛んだりしたかもしれない。
        <br />
        「僕さ……」
        <br />
        「オーエン！」
        <br />
        とはいえ、結局これかと思うと面白くはない。行き場のない苛立ちをその一言に込める。
        <br />
        「おまえのこと大っ嫌い」
        <br />
        突然罵倒されて、泣き出しそうだった顔にさっと困惑の色が浮かんだ。
        <br />
        <br />
        <br />
        <br />
        「全てはこれが原因じゃな」
        <br />
        「呪いがかけられておったようじゃ」
        <br />
        スノウとホワイトが星図盤を見て頷く。全員の目線の先には古びた板状の魔法具がある。
        <br />
        「星図盤ですか……」
        <br />
        リケが興味津々という風に眺める。
        <br />
        「左様。もうなんの魔法も残ってないから触っても大丈夫」
        <br />
        厄災によるものと思われる異変は毎日のように魔法舎に持ち込まれる。今回は中央の国のある町で起こった異変の調査だった。調査自体はつつがなく終わった。しかし、帰還して厄災討伐の礼にともらって来た星図盤を動かしたところ、カインとオーエンの姿が突如消え、この星図盤だけが塔に残されたということらしい。
        <br />
        「こんなもの普通受け取ってくる？」
        <br />
        「まさか呪いがかかってるなんて思わなかったんだよ」
        <br />
        カインとオーエンが言い合うところに「まあまあ」と賢者が割って入る。
        <br />
        「オーエン。お主がちゃんと最後まで見守っていればこんなことにはならなかったろうに」
        <br />
        「おうちに帰るまでが任務じゃからな」
        <br />
        オーエンは厄災の調査が終わるとさっさと姿を消してしまった。その後にカインと賢者が受け取った包みを開けるとこの星図盤が出て来たのだ。
        <br />
        「突然カインとオーエンが消えたのでびっくりしました」
        <br />
        残された星図盤に驚いて、一緒に調査に出ていたリケと賢者が慌てて双子を呼んだのが数刻前。
        <br />
        「でも、本当に無事でよかった。このまま戻ってこないんじゃないかと……」
        <br />
        賢者はそう言うと深く息をついた。
        <br />
        「オーエンがいるなら心配ないと思ったんじゃけど、意外と時間かかったから我もひやっとしたぞ」
        <br />
        「あの謎の手紙のせいだよ。何？　追いかけろって」
        <br />
        ホワイトの言葉はオーエンの癇に障ったらしい。
        <br />
        「手紙？」
        <br />
        「賢者様でしょ。最初っから壊しておけばよかった」
        <br />
        なんのことか意図を取れない賢者にスノウが助け舟を出す。
        <br />
        「多分我らが『呪いが完成する前に脱出してもらわないと』と話したことかのう」
        <br />
        「賢者の意思が星図盤を通じてお主らに伝わったと言うことじゃの」
        <br />
        「役立たず……」
        <br />
        オーエンは深くため息をついた。
        <br />
        「でも、最終的にはあの手紙があってよかったよ。心配かけてすまなかった」
        <br />
        カインは賢者に深く頭を下げる。こういうちょっとした仕草が様になる。
        <br />
        「無事解決したことですし、ご飯にしませんか？　安心したらお腹が空きました」
        <br />
        リケの言葉にカインも頷く。
        <br />
        「確かに。どっと疲れた気がする」
        <br />
        「行きましょう。オーエンも。ネロに甘いものも用意してもらえるように僕も頼みますから」
        <br />
        リケはカインの手を引く。カインは空いた手でオーエンの手を掴んだ。
        <br />
        「ほら、行くぞ」
        <br />
        3人で連なって外に出ると空には満天の星が瞬いている。もうあの奇妙な星は見えない。1人でない世界はうるさくて、2人でない世界は複雑だ。心地よくはない。それなのにオーエンは束の間、こういう日々があってもいいような錯覚に襲われた。
        <br />
        片手に持ったトランクが心なしか重く感じる。
        <br />
        「オーエン」
        <br />
        　賢者はオーエンの隣にやって来て声をかけた。
        <br />
        「何？」
        <br />
        「ちゃんと帰って来てくれてありがとうございます」
        <br />
        「騎士様のことじゃなくて僕のことも心配してくれてたの？」
        <br />
        「当たり前じゃないですか。ああ、でも──」
        <br />
        　賢者は内緒話をするようにはにかんだ。
        <br />
        「心配はもちろんしたんですけど、カインとオーエンなら大丈夫かなという気持ちもありました」
        <br />
        「何それ」
        <br />
        オーエンは眉を寄せる。その信頼がなんとなく面白くない。
        <br />
        このまま彷徨い続けようかと思ったんだよ。そう言ってやりたかったが、結局のところここに帰って来た自分が何を言っても、賢者には響かないこともわかっていた。
        <br />
        「そうそう」と賢者が声をあげた。懐中時計を取り出して頷くとオーエンに向かって笑いかけた。
        <br />
        「オーエン、お誕生日おめでとうございます」
        <br />
        なにそれ。そんな風にオーエンは返すつもりだった。誕生日なんて、気にしたこともない。双子の占いで教えられても、感慨がわくこともなかった。
        <br />
        それなのに──。
        <br />
        「誕生日だったのか？　早く言えよ」
        <br />
        「それなら、ネロにケーキを作ってもらうようにお願いしておけばよかった」
        <br />
        「大丈夫です！　作ってもらうように事前に準備していたので。誕生日なのに帰ってこなかったらどうしようかと……」
        <br />
        3人は楽しそうに話している。そのくせカインはオーエンの手を引っ掴んで離さない。
        <br />
        「いくつになっても誕生日は良いものじゃの」
        <br />
        「そうじゃそうじゃ。苺の載った真っ白なケーキじゃよ」
        <br />
        双子までそうやって囃し立ててくる。
        <br />
        馬鹿馬鹿しい。そう吐き捨てて、ケーキだってなんだって全部めちゃくちゃにしたってよかった。けれど口をついたのは真逆の言葉だった。
        <br />
        「真っ白なケーキはいいね」
        <br />
        　それにカインは頷く。
        <br />
        「誕生日はやっぱり真っ白なクリームのケーキだよな」
        <br />
        満天の星の下。魔法舎への短い道をいく。
        <br />
        <br />
        それは、帰り道。あるいは長い旅の途中。
        <br />
      </p>
    </div>
  );
};
export default Page8;
