/* eslint no-irregular-whitespace: 0 */
import React from 'react';
import Twitter from '../../img/twitter.png';
import Pixiv from '../../img/pixiv.png';

const Page9 = () => {
  return (
    <div className="page-novel">
      <h2 className="page-novel-hader">あとがき</h2>
      <p className="page-novel-text">
        ここまでお読みいただきありがとうございました。
      </p>
      <p className="page-novel-text">
        「WEBオンリーとは…」という状態だったのですが、せっかくなのでWEBならではの作品を作りたいなと思って今回挑戦しました。
        <br />
        めちゃくちゃ大変でしたが、今思うと楽しかったのでまたリベンジしたいです。（が、次は紙の同人誌を…！）
      </p>
      <p className="page-novel-text">
        私は旅をする話が好きでどんなジャンルでも旅をさせる話を書いている気がします。ただ、ファンタジー世界で旅をさせるのは初めてのような気がして、資料がないの大変だな…と思いました。
        <br />
        個人的にはゆるキャン（ピクニックでは？）させたかったので満足です。
      </p>
      <p className="page-novel-text">
        もしよろしければ右下（スマホ版は左下）のお手紙マークよりご感想などいただけますと大変嬉しいです。不具合報告も是非…。
      </p>
      <p className="page-novel-text">2020/11/1 冬月藍</p>
      <button
        type="button"
        className="postscript-sns-link"
        onClick={() =>
          window.open('https://twitter.com/ranfuyutsuki', 'newtab')
        }
      >
        <img src={Twitter} alt="twitter" />
      </button>
      <button
        type="button"
        className="postscript-sns-link"
        onClick={() =>
          window.open('https://www.pixiv.net/users/35332263', 'newtab')
        }
      >
        <img src={Pixiv} alt="pixiv" />
      </button>
    </div>
  );
};
export default Page9;
