import React, { FC } from 'react';
import ReadFlag from '../ReadFlag';
import './PageLink.css';
import SilverStar from './img/star_silver.png';
import GoldStar from './img/star_gold.png';

type Props = {
  readFlag: ReadFlag;
  setClickPageId: React.Dispatch<React.SetStateAction<number>>;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setReadFlag: React.Dispatch<React.SetStateAction<ReadFlag>>;
};

const PageLink: FC<Props> = props => {
  const { readFlag, setClickPageId, setModalIsOpen, setReadFlag } = props;

  const isDisplay = (pageId: number) => {
    // 現在ページよりも前のページは表示
    if (readFlag.pageId >= pageId) {
      return true;
    }

    return false;
  };

  const modalOpenPage = (pageId: number): void => {
    setClickPageId(pageId);
    setReadFlag(new ReadFlag(readFlag.pageId, true));
    setModalIsOpen(true);
  };

  const isLatestPage = (pageId: number): boolean => {
    return readFlag.pageId === pageId;
  };

  return (
    <div className="page-link">
      <div className="page-link-parent">
        {[1, 2, 3, 4, 5, 6].map(pageId => (
          <div className="page-link-child">
            {isDisplay(pageId) && (
              <div id={`page-link-${pageId}`}>
                <button
                  type="button"
                  className="page-link-button"
                  onClick={() => modalOpenPage(pageId)}
                >
                  <img
                    src={isLatestPage(pageId) ? GoldStar : SilverStar}
                    alt="Link"
                    id={`page-link-icon-${pageId}`}
                  />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default PageLink;
