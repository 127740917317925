/* eslint no-irregular-whitespace: 0 */
import React from 'react';

const Page2 = () => {
  return (
    <div className="page-novel">
      <h2 className="page-novel-hader">午前8時、朝市にて</h2>
      <p className="page-novel-text">
        右を見ても左を見ても人がいる。がやがやとした喧騒にオーエンは顔をしかめた。つい先ほどまで人影などまるでない空の上にいたはずなのに、気がつけば人混みの中にいた。オーエンは最初に自身の記憶を疑う。しかし、「おーい」と人混みの向こうで手を振ったカインが驚いた顔でこちらに向かってくるのに、これもまたあの星がもたらした事態だと思い直した。
        <br />
        「突然ここに現れたからびっくりした」
        <br />
        カインはさして驚いてなさそうなからっとした顔でそう言った。オーエンは自分の記憶に問題がなかったことに安堵を感じる自分に苛立つ。
        <br />
        たとえ少しくらい忘れたことがあったところで、困ることはないじゃないか。自分ではないものがこの身に潜んでいたとしても、それで都合が悪いならみんな殺してしまえばいい。
        <br />
        心の中でそう言い聞かせてから、いつも通りの表情を取り繕って顔をあげた。
        <br />
        「騎士様も無事で何より。これで少しは慎重という言葉が理解できた？」
        <br />
        「……悪かったよ」
        <br />
        カインのことをなじるとほんの少し気分が良くなった。青空の上には星が瞬いている。
        <br />
        <br />
        <br />
        <br />
        星を東へと追いかけていくと、手を伸ばせば届くほどの距離になった。魔法使いとはいえ、星が掴めるはずがない。つまり、これは本物の星というわけではないのだろう。
        <br />
        オーエンが警戒しながら、観察する横から不用意に腕が伸びる。
        <br />
        「これを捕まえればいいんだろう？」
        <br />
        言葉は疑問形だったが、オーエンの耳にその言葉が届いた時にはカインの手の中に星はすっかり収まっていた。そして、瞬きの後に広がっていたのがこの光景だった。
        <br />
        <br />
        <br />
        <br />
        「ここは西の国らしいな」
        <br />
        2人が放り出されたのは市場だった。カインは店を覗くと店主に気さくに話しかけ、さりげなくここがどこなのかを確認した。西の国のとある街。カインにも聞き覚えがあるくらいのそこそこ大きな都市だ。
        <br />
        「僕らが向かっていたのは？」
        <br />
        「東だな」
        <br />
        カインは肩を竦める。こうなってしまったのだからしょうがないと、すでに割り切った顔だ。オーエンもため息を1つついて、頭上の星を伺った。星を捕まえるというのは、どうも簡単なことではないらしい。
        <br />
        「お兄さんたち、今朝漁れた魚はどうだい？」
        <br />
        「西の国の香辛料は質が違うよ」
        <br />
        道を歩いていると両側から声をかけられる。鬱陶しそうな顔を隠さないオーエンとは対照的に、カインは愛想の良い笑顔を浮かべている。
        <br />
        「見えていないのに」
        <br />
        「なんか言ったか？」
        <br />
        カインの左手はオーエンの右手を握っている。カインは触れるまで人や魔法使いを認識でない。だから、先を歩くオーエンの手に引かれていた。混み合った市場の中では肩が触れるのは日常茶飯事のようで、ぶつかったとしても周りの人たちもさして気にした様子はない。それでも人の気配があるのに見えない中を歩くのは難儀なことだった。当然店先に立つ人たちのことはほとんど見えていなかったが、カインはいつも通りの気の良い顔で応対していた。
        <br />
        市場の途中で唐突にオーエンの足が止まる。カインが問いかけるまでもなく、甘い香りのせいだとすぐにわかった。
        <br />
        「いらっしゃい」
        <br />
        店先には菓子が並んでいた。特に多いのはチョコレートだ。
        <br />
        「うちは西の国だけじゃなく、中央の国や東の国からもチョコレートを仕入れてるんだよ。お兄さん、お目が高いね」
        <br />
        カインにとってはどれも同じに見えるが、産地が違えば味も変わるらしい。オーエンは店主の説明を聞いて吟味している。
        <br />
        「じゃあここからここまで全部」
        <br />
        「毎度」
        <br />
        オーエンは商品を並べた台の上を端から端まで指し示した。カインが一生かけても食べないだろう量のチョコレートだ。
        <br />
        「こんなに買ってどうするんだよ」
        <br />
        「食べるに決まってるでしょ」
        <br />
        支払いを済ませると、オーエンはチョコレートの入った紙袋を抱えて満足気な顔を見せる。カインは毒気を抜かれて、自分も何か買おうかと店の前に並ぶ商品を見やる。
        <br />
        「お兄さん」
        <br />
        声をかけられたカインが目を向けると、そこには古い道具が並んでいた。
        <br />
        「魔法使いだろう」
        <br />
        声をかけてきたのは男の声だった。カインが足を止めると、オーエンも足を止め、胡散臭そうに店を眺める。
        <br />
        「魔道具を売ってるんだ」
        <br />
        「そうだよ。どうだい？　いいのが揃ってるよ」
        <br />
        オーエンは興味がないらしく先へ進もうとしたが、カインは興味を引かれた。人間に紛れて暮らしていた彼にとって、魔法を使うための道具というものは、魔法舎で他の魔法使いたちと出会ってから知ったものだった。
        <br />
        「大したものはないよ」
        <br />
        オーエンは素っ気なかった。大したものがないと彼が言うのならそうなのだろう。それでもカインはこの奇妙な旅の土産物として1つくらい持ち帰ってやろうと言う気になっていた。
        <br />
        「これなんてどうだい？」
        <br />
        「鈴？」
        <br />
        店主が出してきたのは古い鈴だった。1本の紐に2つの鈴がぶら下がっている。鈴を手に取る時にさりげなく店主の手に触れると、彼は口元に笑みを浮かべている。正直なところ、声色で想像していたより数段胡散臭い。
        <br />
        鈴は振っても音はしない。怪訝な顔をしていると、店主が説明してくれた。
        <br />
        「失せ物を見つけるためのものさ。なくしたくないものにつけておけばいい」
        <br />
        「それはいい」
        <br />
        ガラクタに支払うには多すぎる額を払ってカインの手元に鈴が残った。紐から鈴を一つ抜き取ると、それを上着のポケットにしまい、紐のついた方の鈴をオーエンに渡す。
        <br />
        「なにこれ……」
        <br />
        「失せ物を見つける鈴だって」
        <br />
        「なんで僕に」
        <br />
        「はぐれたら面倒だろ」
        <br />
        「僕は騎士様が部屋に転がしている靴下や手帳じゃないんだけど」
        <br />
        「まあ、そう言うなって。魔法舎に帰ったら返してくれ」
        <br />
        オーエンは何か言いたげだったが、鈴をしばらく睨みつけてからポケットにしまった。
        <br />
        <br />
        <br />
        <br />
        星は南の空に輝いている。
        <br />
      </p>
    </div>
  );
};
export default Page2;
