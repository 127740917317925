/* eslint no-irregular-whitespace: 0 */
import React from 'react';

const Page3 = () => {
  return (
    <div className="page-novel">
      <h2 className="page-novel-hader">12時、高原でランチを</h2>
      <p className="page-novel-text">
        追いついた星は、やはり触れた瞬間に弾けて消えた。
        <br />
        <br />
        <br />
        <br />
        「のどかだな」
        <br />
        カインとオーエンがいるのは山々に囲まれた高山地帯の一角だった。山々といっても北の峻厳なそれではなく、穏やかな空気と色づいた木々の葉が美しい。この季節の高地でこの気候──となれば、おそらく南の国だろう。
        <br />
        どこまで見渡しても赤く色づいた紅葉と晴れた青い空が続いている。中央の国の王都よりも涼しいが、寒すぎることはない。適度に湿り気のある澄んだ空気を吸うだけで生き返った心地がする。
        <br />
        「昼飯にでもするか」
        <br />
        どんなに美味しい空気でも腹は満たされない。天気もいいしこのあたりで昼食にしようとオーエンの方を見やる。オーエンも小さくため息をつくと魔道具であるトランクの留め具に手を触れた。
        <br />
        「《クアーレ・モリト》」
        <br />
        トランクの中には獰猛な飼い犬が眠っている。カインは身構えたがオーエンがトランクを開けるとそこに犬の姿はなかった。そういう作りのトランクなのだということをカインは初めて知る。
        <br />
        「驚かせるなよ」
        <br />
        「荷物を入れられないトランクなんて不便だと思わない？」
        <br />
        オーエンはトランクの中に腕を突っ込むと折りたたみの椅子を取り出した。それから小さなコンロ、薬缶、ティーポットとティーカップをひと揃え。朝市で買い込んだチョコレートも。
        <br />
        目を丸くするカインを見ると、少し考えて、トランクの中をしばらくかき回してから幾分古すぎる椅子と飲み口が欠けたティーカップを出した。
        <br />
        「それしかないんだけど」
        <br />
        オーエンは魔法で薬缶に水を集め、コンロに火をつけるとそこに薬缶をのせて湯を沸かした。
        <br />
        「意外だ」
        <br />
        「僕がお茶を淹れるのがそんなに不思議？」
        <br />
        「そういうわけじゃないんだが……」
        <br />
        普段のオーエンの生活をカインはよく知らない。気が向けば食堂にいるし、彼のお気に入りであるクロエやヒースクリフを見つけて声をかけていることもある。しかし、基本的には顔を合わせることはそう多くなく、こうやって普通のことをしていることが意外に感じられる。
        <br />
        「誰かにも同じようなことを言われた。でも、思い出せない」
        <br />
        引っかかることがあるのかオーエンは眉を寄せる。それを言ったのが魔法舎の誰かということであれば、カインもその人物には思い至らない。けれど、なぜかそういうことを言う人物を知っているような気もする。
        <br />
        「ああ……でも物持ちがいいのは意外だな」
        <br />
        「捨てなくても困らないから捨てない。現に今役立ってるでしょう？」
        <br />
        ごもっとも、とカインは頷いた。オーエンは手慣れた様子で白磁のポットに缶に入った茶葉を落とすと沸かしたお湯を入れる。
        <br />
        カインも朝市で買った昼食を広げる。パン屋で買ったパンにすぐ近くの肉屋で買ったベーコンをその場で挟んでもらった、大雑把なサンドイッチだった。
        <br />
        オーエンは紙に包まれたチョコレートを齧る。魔法舎では普通の食事も摂っているが、オーエン自身に献立を考えさせると三食甘味になりそうだ。
        <br />
        オーエンの入れた茶はごくごく普通の紅茶だった。変わった香りがするわけでもない。縁が欠けていてもティーカップはちゃんとティーカップの役割を果たす。
        <br />
        澄んだ空気と美味しい昼食、温かいお茶。カインは正直な気持ちを口にする。
        <br />
        「こんな状況だけど楽しいな」
        <br />
        「危機感がないんじゃない？」
        <br />
        「そうは言っても災厄絡み以外で中央の国の外に出ることってあんまりなかったし……。いや、今のこの状況が災厄絡みの可能性があるのもわかってるんだが……」
        <br />
        なんだか旅行みたいだとカインは思うのだ。
        <br />
        「国の外に出ること、そんなに珍しい？」
        <br />
        「魔法使いならともかく人間はそんなに長い距離を移動しないだろ」
        <br />
        魔法使いは忘れがちだが、人間は魔法使いのように速く、長い距離を移動することはできない。大抵の場合、生まれた町で育ち、そこで死ぬのだ。仕事や旅行で移動するにしても、国を跨ぐことは少ない。カインは人の中で人として生きてきたからそれが身にしみている。
        <br />
        「騎士団に入ってからは、仕事じゃない限り、国境は超えない」
        <br />
        思い返せば王族の護衛で東の国に行ったことがあるような気がする。本当にその程度だ。
        <br />
        「人の中で紛れて暮らすなら、永遠に旅をし続けることになるよ。楽しみだね」
        <br />
        オーエンはにっこりと笑った。綺麗な顔に皮肉を乗せている。魔法使いの寿命は人間より遥かに長い。人間の善き隣人であろうとするのなら、怪しまれない程度に暮らす場所を移す必要がある。
        <br />
        「その時は椅子とティーセットを準備するよ」
        <br />
        言い返すとオーエンは面白くなさそうな顔をした。北の国に城を構えていたオズの伝説とは異なり、魔法使いオーエンの伝承は神出鬼没であるのが常だった。となれば、今でこそ魔法舎という拠点はあるが、オーエンも長い長い旅の中にあったのかもしれない。
        <br />
        「それより、せっかくだからオーエンが今までに行った中で一番面白いところの話でも聞かせてくれよ」
        <br />
        「なんでそんなこと」
        <br />
        「今度行きたいから」
        <br />
        オーエンは茶を啜ると静かに告げる。
        <br />
        「忘れちゃったよ。興味ないもの」
        <br />
        それが真実なのか嘘なのか、カインにはわからなかった。ただ、オーエンの言葉にはそれ以上踏み込ませないようなものがある
        <br />
        ちょうどその時だった。カインの後ろ側から声がした。
        <br />
        「もしもし」
        <br />
        女性の声だった。
        <br />
        「はい」
        <br />
        「あなたたち旅人さんかしら。このあたり天気が変わりやすいの。雲が出てきたからそろそろ片付けないと雨に降られてしまうかもしれないわ」
        <br />
        声色と口調からは老女という印象がある。おそらくはこのあたりに住んでいるのだろう。
        <br />
        「ご親切にありがとうございます」
        <br />
        「このあたりは何もないでしょう。よろしければ雨が通りすぎるまで私の家の軒先をお貸ししましょうか？」
        <br />
        「いえ……」
        <br />
        もう行きます、と言おうとしてカインは口を噤んだ。魔法使いでなければ歩いて山を下りるしかない。それには彼女の申し出を断るには不自然に思えた。
        <br />
        「あなたには空に何が見える？」
        <br />
        カインが口籠っている間にオーエンが彼女に話しかけた。
        <br />
        「そうね。少し雲が多くなってきたけれど、青い空が見えるわ」
        <br />
        「それだけ？」
        <br />
        「ええ。今日はそれだけ」
        <br />
        カインとオーエンには真昼の空に輝く星が見える。今度は西の方へ。
        <br />
        オーエンは魔法でトランクの中に荷物を全部仕舞い込むと、代わりに箒を取り出した。カインは老女に触れていないから、彼女の姿は見えなかった。けれど、驚いた気配も声も聞こえない。
        <br />
        「じゃあね」
        <br />
        オーエンは星に向かって飛ぶ。カインも箒を取り出すとそれを追いかけて並んだ。
        <br />
        「ありがとうございます！ 雨が降る前に行きます！」
        <br />
        カインが宙から叫ぶと「お気をつけて」という品の良い声が聞こえた。
        <br />
        「あの人、気づいていたのか？」
        <br />
        「そうみたい」
        <br />
        何故だろうかとカインが考えていると、オーエンはくすくすと笑った。
        <br />
        「このあたりはね、よく飛んでいる魔法使いが見えるんだよ」
        <br />
        そりゃあもう流れ星と同じくらいには。
        <br />
        青い青い空の間に丘陵が見える。箒でこの空を駆け抜けたい他の魔法使いたちの気持ちがカインにもよくわかった。
        <br />
        地上にいる彼女は何を思っているのだろう。星のように彼らを見上げるのだろうか。それとも、山道を行く旅人のように見守るのだろうか。
        <br />
        手を振っても見えるはずはない。けれど、カインは大きく元いた場所へと手を大きく振った。
        <br />
      </p>
    </div>
  );
};
export default Page3;
