/* eslint no-irregular-whitespace: 0 */
import React from 'react';

const Page6 = () => {
  return (
    <div className="page-novel">
      <h2 className="page-novel-hader">午後9時、星空の中で</h2>
      <p className="page-novel-text">
        あなたたちならきっと、
        <br />
        その先の文字はない。誰が書いたのかもわからない。
        <br />
        けれど、そうやって願う人を知っている気がする。
        <br />
        <br />
        <br />
        <br />
        すっかり暗くなった星空の中をカインはオーエンを追いかけて飛んでいる。海岸を離れ、あれからオーエンとは一言も口を聞いていない。会話がないと1人で考えることが増える。
        <br />
        大事なことを忘れているような気がした。こうなる前のことを考えようとすると、記憶の中にひどく不鮮明な部分がある。朝起きて魔法舎の食堂で朝食をとる。ネロと言葉を交わして、それから廊下でリケとばったり会って一緒に中央の国の塔へと向かった。北の国の魔法使いが手伝ってくれると言われて──。
        <br />
        「誰にだ……？」
        <br />
        スノウでもホワイトでもない。オズでもない。けれど、あの場でその人が言った言葉に誰も疑わなかった。あの場には魔法使いでない誰かがいた。
        <br />
        オーエンにも確かめてみたかったが、オーエンはカインの方を振り返りもせずに先を飛んでいた。例の星は北の方角にある。あの星は魔法でできている。つまり、今ここにいる自分たちは魔法に囚われているのだ。大掛かりな魔法ほど、完成には時間がかかる。あの手紙が言うように夜がタイムリミットだとしたら、もう猶予はない。
        <br />
        「オーエン！」
        <br />
        追いついてオーエンを呼び止める。聞こえていないわけはないのに、オーエンはカインを無視して飛び続ける。三度声をかけてからカインは速度を上げてオーエンの前に回り込んだ。
        <br />
        「《クーレ・メミニ》」
        <br />
        「《グラディアス・プロセーラ》」
        <br />
        オーエンの魔法をカインは受け流す。しかし、反動でぐるりと天地が逆転した。オーエンはひっくり返ったカインを嘲笑う。
        <br />
        「そのまま夜が明けるまで逆さまになってる？　それともこのまま地面に落っこちる？」
        <br />
        「どっちも嫌だ」
        <br />
        全くもって喧嘩をしている場合ではない。しかし、呪文を唱えても反転した姿勢が戻らない。オーエンの魔法だ。オーエンは飽きたおもちゃを見る目でカインを眺めている。
        <br />
        「なあ、オーエン。こんなことしている場合じゃないだろ」
        <br />
        「なぜ？」
        <br />
        「なぜって……あの星をなんとかするのが先だろ」
        <br />
        ぐるりとカインの視界が再び反転した。オーエンはカインでなく冷ややかな目を星に向けている。
        <br />
        「《クアーレ・モリト》」
        <br />
        オーエンが呪文を唱えると、星はオーエンの手の中に収まった。こうして見ると、星はあまりに小さく儚い。星の魔力よりも、オーエンから漂う魔力の方が圧倒的だった。
        <br />
        「星を捕まえるなんて、まどろっこしいのはもうやめにしよう」
        <br />
        オーエンはそう告げると、星を潰すようにぱん、と一つ手を打った。
        <br />
      </p>
    </div>
  );
};
export default Page6;
