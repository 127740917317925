import React, { FC } from 'react';
import Page1 from './Page/Page1';
import Page2 from './Page/Page2';
import Page3 from './Page/Page3';
import Page4 from './Page/Page4';
import Page5 from './Page/Page5';
import Page6 from './Page/Page6';
import Page7 from './Page/Page7';
import Page8 from './Page/Page8';
import Page9 from './Page/Page9';

type Props = {
  pageId: number;
};
const Page: FC<Props> = props => {
  const { pageId } = props;

  return (
    <div>
      {pageId === 1 && <Page1 />}
      {pageId === 2 && <Page2 />}
      {pageId === 3 && <Page3 />}
      {pageId === 4 && <Page4 />}
      {pageId === 5 && <Page5 />}
      {pageId === 6 && <Page6 />}
      {pageId === 7 && <Page7 />}
      {pageId === 8 && <Page8 />}
      {pageId === 9 && <Page9 />}
    </div>
  );
};
export default Page;
