import React, { FC, useState } from 'react';
import './Cover.css';
import ReadFlag from '../ReadFlag';
import NovelModal from './Novel/NovelModal';
import PostscriptModal from './PostscriptModal';
import PageLink from './PageLink';
import Logo from './img/logo.png';
import Letter from './img/letter.png';
import Bird from './img/tori.png';
import Background1 from './img/1.jpg';
import Background2 from './img/2.jpg';
import Background3 from './img/3.jpg';
import Background4 from './img/4.jpg';
import Background5 from './img/5.jpg';
import Background6 from './img/6.jpg';
import Paper from './img/paper.png';

type Props = {
  readFlag: ReadFlag;
  setReadFlag: React.Dispatch<React.SetStateAction<ReadFlag>>;
  clickPageId: number;
  setClickPageId: React.Dispatch<React.SetStateAction<number>>;
  isModalOpen: boolean;
  setModalIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

const Cover: FC<Props> = props => {
  const [autoOpenPage, setAutoOpenPage] = useState('');
  const [isBackgroundLoaded, setIsBackgroundLoaded] = useState(false);
  const {
    readFlag,
    setReadFlag,
    clickPageId,
    setClickPageId,
    isModalOpen,
    setModalIsOpen,
  } = props;

  const backgroundLoading = () => {
    const array = [
      Background1,
      Background2,
      Background3,
      Background4,
      Background5,
      Background6,
      Paper,
      Letter,
      Bird,
    ];

    array.forEach(b => {
      const img = new Image();
      img.src = b;
    });
    setIsBackgroundLoaded(true);
  };

  const incrementReadFlagPageId = () => {
    const pageId = readFlag.pageId + 1;
    const isRead = false;
    setReadFlag(new ReadFlag(pageId, isRead));
  };

  const moveFirstPage = () => {
    const pageId = 1;
    const isRead = false;
    setReadFlag(new ReadFlag(pageId, isRead));
  };

  const background = (pageId: number) => {
    switch (pageId) {
      case 0:
        return Background1;
      case 1:
        return Background1;
      case 2:
        return Background2;
      case 3:
        return Background3;
      case 4:
        return Background4;
      case 5:
        return Background5;
      case 6:
        return Background6;
      default:
        return Background6;
    }
  };

  const reloadPage = () => {
    window.location.reload();
  };

  const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

  if (!isBackgroundLoaded) {
    backgroundLoading();
  }

  if (!isBackgroundLoaded) {
    // 読み込み中であることがわかる何かをいれる
    return <div>読み込み中</div>;
  }

  if (readFlag.pageId === 0) {
    // トップページ
    return (
      <div
        className="cover"
        style={{ backgroundImage: `url(${background(readFlag.pageId)})` }}
      >
        <div id="toppage">
          <div className="cover-toppage-title">
            <button type="button" onClick={() => moveFirstPage()}>
              <img src={Logo} alt="Bon Voyage" width="70%" />
            </button>
            <div className="description">
              <p>「魔法使いの約束」カイン×オーエン二次創作企画のサイトです。</p>
              <p>謎の手紙と不思議な星に導かれた小旅行へようこそ。</p>
              <p>PC、タブレットでの閲覧を推奨します。</p>
              <p className="description-small">
                ブラウザの「戻る」ボタンを使用せずにお楽しみください。
              </p>
              <p className="description-small">無断転載はご遠慮ください</p>
              <p className="message">
                旅のスタートはロゴをクリックしてください。
                <br />
                良い旅を！
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (readFlag.pageId === 1) {
    setClickPageId(1);
    sleep(1000).then(() => {
      setModalIsOpen(true);
    });

    return (
      <div
        className="cover"
        style={{ backgroundImage: `url(${background(readFlag.pageId)})` }}
      >
        <div className="cover-novel-page">
          {readFlag.pageId > 0 && (
            <div className="cover-novel-page-title-logo">
              <img src={Logo} alt="Bon Voyage" width="20%" />
            </div>
          )}
          {readFlag.pageId > 0 && (
            <PageLink
              readFlag={readFlag}
              setClickPageId={setClickPageId}
              setModalIsOpen={setModalIsOpen}
              setReadFlag={setReadFlag}
            />
          )}
          {readFlag.pageId > 0 && (
            <NovelModal
              isModalOpen={isModalOpen}
              setModalIsOpen={setModalIsOpen}
              clickPageId={clickPageId}
              readFlag={readFlag}
              incrementReadFlagPageId={incrementReadFlagPageId}
              setAutoOpenPage={setAutoOpenPage}
            />
          )}
        </div>
      </div>
    );
  }

  if (autoOpenPage === 'dark' && readFlag.pageId === 7 && !readFlag.isRead) {
    setClickPageId(7);
    // 1秒後に小説を表示
    sleep(1000).then(() => {
      setModalIsOpen(true);
    });

    // 7ページ
    return (
      <div className="cover" id="cover-7">
        <NovelModal
          isModalOpen={isModalOpen}
          setModalIsOpen={setModalIsOpen}
          clickPageId={clickPageId}
          readFlag={readFlag}
          incrementReadFlagPageId={incrementReadFlagPageId}
          setAutoOpenPage={setAutoOpenPage}
        />
      </div>
    );
  }
  if (autoOpenPage === 'ending' && readFlag.pageId === 8 && !readFlag.isRead) {
    setClickPageId(8);
    // 0.5秒後に小説を表示
    sleep(500).then(() => {
      setModalIsOpen(true);
    });

    // 8ページ
    return (
      <div
        className="cover"
        style={{ backgroundImage: `url(${background(readFlag.pageId)})` }}
      >
        <div className="cover-novel-page">
          <div className="cover-novel-page-title-logo">
            <img src={Logo} alt="Bon Voyage" width="20%" />
          </div>

          <NovelModal
            isModalOpen={isModalOpen}
            setModalIsOpen={setModalIsOpen}
            clickPageId={clickPageId}
            readFlag={readFlag}
            incrementReadFlagPageId={incrementReadFlagPageId}
            setAutoOpenPage={setAutoOpenPage}
          />
        </div>
      </div>
    );
  }
  if (readFlag.pageId === 9 && !readFlag.isRead) {
    // 最終ページ
    return (
      <div
        className="cover"
        style={{ backgroundImage: `url(${background(readFlag.pageId)})` }}
      >
        <div id="thanks-page">
          <div className="cover-novel-page-title-logo">
            <img src={Logo} alt="Bon Voyage" width="20%" />
          </div>
          <div className="cover-thanks-page-title">
            <div className="cover-thanks-message">
              <h2 className="cover-thanks-header">The End.</h2>
              <p className="cover-thanks-description">
                2人の旅にお付き合いいただき、ありがとうございました！
              </p>
            </div>
            <div className="cover-thanks-button">
              <button type="button" onClick={() => reloadPage()}>
                <p className="cover-thanks-page-title-reload-button-description">
                  はじめにもどる
                </p>
              </button>
            </div>
          </div>
          <div className="cover-thanks-page-web-clap">
            <button
              type="button"
              className="cover-thanks-page-title-webclap-button"
              onClick={() =>
                window.open('https://www.idee-novel.com/contact/', 'newtab')
              }
            >
              <img src={Letter} alt="Web Clap" width="100%" />
            </button>
          </div>
          <div className="cover-thanks-page-postscript">
            <button
              type="button"
              className="cover-thanks-page-postscript-button"
              onClick={() => setModalIsOpen(true)}
            >
              <img src={Bird} alt="postscript" width="100%" />
            </button>
          </div>
          <PostscriptModal
            isModalOpen={isModalOpen}
            setModalIsOpen={setModalIsOpen}
          />
        </div>
      </div>
    );
  }

  // それ以外
  return (
    <div
      className="cover"
      style={{ backgroundImage: `url(${background(readFlag.pageId)})` }}
    >
      <div className="cover-novel-page">
        {readFlag.pageId > 0 && (
          <div className="cover-novel-page-title-logo">
            <img src={Logo} alt="Bon Voyage" width="20%" />
          </div>
        )}
        {readFlag.pageId > 0 && (
          <PageLink
            readFlag={readFlag}
            setClickPageId={setClickPageId}
            setModalIsOpen={setModalIsOpen}
            setReadFlag={setReadFlag}
          />
        )}
        {readFlag.pageId > 0 && (
          <NovelModal
            isModalOpen={isModalOpen}
            setModalIsOpen={setModalIsOpen}
            clickPageId={clickPageId}
            readFlag={readFlag}
            incrementReadFlagPageId={incrementReadFlagPageId}
            setAutoOpenPage={setAutoOpenPage}
          />
        )}
      </div>
    </div>
  );
};
export default Cover;
