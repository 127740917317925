import React, { FC } from 'react';
import Modal from 'react-modal';
import ReadFlag from '../../ReadFlag';
import Novel from './Novel';
import './NovelModal.css';

Modal.setAppElement('#root');

type Props = {
  isModalOpen: boolean;
  setModalIsOpen: any;
  clickPageId: number;
  readFlag: ReadFlag;
  incrementReadFlagPageId: VoidFunction;
  setAutoOpenPage: React.Dispatch<React.SetStateAction<string>>;
};
const NovelModal: FC<Props> = props => {
  const {
    isModalOpen,
    setModalIsOpen,
    clickPageId,
    readFlag,
    incrementReadFlagPageId,
    setAutoOpenPage,
  } = props;

  const autoOpenModal = (openPage: string): void => {
    setAutoOpenPage(openPage);
  };

  const closeModal = () => {
    // 既読ページを開いた場合はカウントアップしない
    if (readFlag.pageId <= clickPageId) {
      // 最終ページに到達したらカウントアップをやめる
      if (readFlag.pageId < 9) {
        incrementReadFlagPageId();
      }
    }
    if (readFlag.pageId === 6) {
      // 6ページ目を開いたら自動でモーダルが開く準備をする
      const openPage = 'dark';
      autoOpenModal(openPage);
    }

    if (readFlag.pageId === 7) {
      // 7ページ目を開いたら自動でモーダルが開く準備をする
      const openPage = 'ending';
      autoOpenModal(openPage);
    }
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Example Modal"
      className="novel-modal"
      overlayClassName="novel-modal-overlay"
    >
      <Novel pageId={clickPageId} />
      <div className="novel-modal-close">
        <button
          type="button"
          onClick={closeModal}
          className="novel-modal-close-button"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
export default NovelModal;
