import React, { FC } from 'react';
import Modal from 'react-modal';
import './Novel/NovelModal.css';
import Page from './Novel/Page';

Modal.setAppElement('#root');

type Props = {
  isModalOpen: boolean;
  setModalIsOpen: any;
};

const PostscriptModal: FC<Props> = props => {
  const { isModalOpen, setModalIsOpen } = props;

  const closeModal = () => {
    setModalIsOpen(false);
  };

  return (
    <Modal
      isOpen={isModalOpen}
      contentLabel="Example Modal"
      className="novel-modal"
      overlayClassName="novel-modal-overlay"
    >
      <div className="popup-novel">
        <Page pageId={9} />
      </div>
      <div className="novel-modal-close">
        <button
          type="button"
          onClick={closeModal}
          className="novel-modal-close-button"
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
export default PostscriptModal;
