interface ReadFlag {
  pageId: number;
  isRead: boolean;
}

class ReadFlag {
  constructor(pageId: number, isRead: boolean) {
    this.pageId = pageId;
    this.isRead = isRead;
  }
}

export default ReadFlag;
